import React, { useContext, useEffect, useRef, useState } from "react";
import { useHistory } from "react-router";
import { AppContext } from "../Context/AppContext";
import { strings } from "../Common/String";
import Button from "../Component/Button";
import EditPen from "../Component/EditPen";
import TextInput from "../Component/TextInput";
import SignInButton from "../Component/SignInButton";
import Count from "../Component/CountComponent/Count";
import { LeadService } from "../Services/LeadService";
import OTPInput from "../Component/OTPInput";
import { OTP_TYPES } from "../Common/Constants";
import Header from "../Header";
import Footer from "../footer";
import { PATH } from "../Common/Path";
import queryString from "query-string";

const Home = (props) => {
  //-----------variable declaration---------------
  const { user, setUser, setLoading } = useContext(AppContext);
  const history = useHistory();
  const [showOTPBlock, setShowOTPBlock] = useState(false);
  const [showresendotp, setshowresendotp] = useState(false);
  const [mobile, setMobile] = useState("");
  const [otp, setOTP] = useState("");
  const [visibleContinue, setVisibleContinue] = useState(true);
  const [visibleSubmit, setVisibleSubmit] = useState(false);
  const [showpencil, setShowpencil] = useState(false);
  const [disabled, setDisabled] = useState(false);
  const [apiMessage, setApiMessage] = useState("");
  const [showNRI, setShowNRI] = useState(true);
  const [restartTimer, setRestartTimer] = useState(0);
  const phoneRef = useRef();
  const otpRef = useRef();
  const [referralId, setReferralId] = useState(null);

  useEffect(() => {
    phoneRef.current.focus();
    if (props.location.search) {
      let qString = queryString.parse(props.location.search);
      const referral_id = qString.referral_id || null;
      if (referral_id && referral_id.length > 0) {
        setReferralId(referral_id);
      }
    }
  }, []);

  //--------------------------------------------------------------------------------
  /**
   * counter finish message
   */
  const counterFinished = () => {
    setshowresendotp(true);
  };

  /**
   * onclick opencil icon
   * @param {} event
   */

  const handlepenmcil = (event) => {
    event.preventDefault();
    //if (validate()) {
    setShowOTPBlock(false);
    setDisabled(false);
    setShowpencil(false);
    setshowresendotp(false);
    setVisibleSubmit(false);
    setVisibleContinue(true);
    setShowNRI(true);
    setApiMessage("");
    //}
  };
  /**
   * mobile validation
   * @param {*} e
   */
  const onChange = (e) => {
    setApiMessage("");
    setMobile(e.target.value);
  };
  /**
   * otp validation
   * @param {} e
   */
  const onOTPchange = (e) => {
    setApiMessage("");
    if (e.target.value.length !== null) {
      setOTP(e.target.value);
    } else {
      setOTP("");
    }
  };
  /**
   * OTP Requesting
   * @param {*} type
   */
  const requestOTP = (type) => {
    if (validateMobile()) {
      setLoading(true);
      setApiMessage("");
      LeadService.requestOTP(type, mobile, null, referralId)
        .then((data) => {
          if (data.success) {
            setShowOTPBlock(true);
            setShowpencil(true);
            setVisibleSubmit(true);
            setVisibleContinue(false);
            setDisabled(true);
            setShowNRI(false);
            setshowresendotp(false);
            setRestartTimer((old) => old + 1);
            otpRef.current.focus();
          } else {
            setApiMessage(data.error);
          }
        })
        .catch((error) => {
          console.error(error);
        })
        .finally(() => setLoading(false));
    }
  };
  /**
   * for request otp
   * @param {*} type
   */
  const handleSubmit = (event) => {
    event.preventDefault();
    requestOTP(OTP_TYPES.SMS);
  };

  const mandatoryValidationVerify = () => {
    let isValid = true;
    if (!otp) {
      setApiMessage(strings.mandatory);
      isValid = false;
    } else {
      setApiMessage("");
    }
    return isValid;
  };

  /**
   * validate otp
   */
  const handleOTPSubmit = () => {
    if (mandatoryValidationVerify()) {
      setLoading(true);
      setApiMessage("");
      LeadService.validateOTP(OTP_TYPES.SMS, mobile, null, otp, referralId)
        .then((data) => {
          if (data.success) {
            setLoading(false);
            const is_editable = data.is_editable;
            let other = { is_editable: true };
            if (data.email && is_editable === false) {
              other = { email: data.email, is_editable };
            }
            setUser({ ...user, mobile, ...other });
            history.replace(PATH.EMAIL_PAGE);
          } else {
            setApiMessage(data.error);
            setLoading(false);
          }
        })
        .catch((error) => {
          console.error(error);
          setLoading(false);
        });
    }
  };
  /**
   * validation check
   * @returns
   */
  const validateMobile = () => {
    let isValid = true;
    if (!mobile) {
      setApiMessage(strings.mandatory);
      isValid = false;
    } else {
      if (mobile.length === 10) {
        isValid = true;
      } else {
        setApiMessage(strings.phone_error);
        isValid = false;
      }
      return isValid;
    }
  };

  const onSignInClick = () => {
    history.replace(
      `${PATH.SIGNIN_PAGE}${referralId ? `?referral_id=${referralId}` : ""}`
    );
  };

  const openReKYC = () => {
    history.replace(
      `${PATH.REKYC_SIGNIN_PAGE}${referralId ? `?referral_id=${referralId}` : ""
      }`
    );
  };

  return (
    <>
      <Header />
      <div className="inner pb-4">
        <div className="container">
          <div className="row">
            <div className="col-md-50">
              <div className="images-add">
                <img
                  src={process.env.PUBLIC_URL + "/img/phone-verification.gif"}
                  alt=""
                />
              </div>
            </div>
            <div className="col-md-50">
              <form onSubmit={handleSubmit}>
                <div className="s-box">
                  <h1 className="text-center pb-2">{strings.phone_header}</h1>
                  {showNRI && (
                    <p className="text-center mb-0">
                      {/* {strings.nri_content}
                      <a href="#">here</a> */}
                    </p>
                  )}
                  <TextInput
                    reference={phoneRef}
                    textType="number"
                    classes="inp pt-3"
                    fieldName="phone"
                    fieldValue={""}
                    enabDisab={disabled}
                    textClasses={"mob"}
                    textMaxLength="10"
                    //value={this.state.input.phone}
                    textChange={onChange}
                    holder={strings.entermobile}
                    textId="phone"
                    classes1="text-danger errorfont mh-18"
                    classes2={"india"}
                    postfix={
                      showpencil && (
                        <EditPen
                          classes={"edit-btn1"}
                          imgsrc={process.env.PUBLIC_URL + "/img/pen.png"}
                          click={handlepenmcil}
                        />
                      )
                    }
                  />
                  {showOTPBlock && (
                    <OTPInput
                      reference={otpRef}
                      divClasses="inp pt-3"
                      maxLength="6"
                      classes={"mb-0"}
                      textType="number"
                      otpPlaceholder={strings.otpenter}
                      onChange={onOTPchange}
                      textId={otp}
                      postfix={
                        <div className="time-otp">
                          <Count
                            onFinish={counterFinished}
                            restart={restartTimer}
                          />
                        </div>
                      }
                    />
                  )}

                  <div
                    id="txterror"
                    name="txterror"
                    className=" api-errormessage text-danger mh-25"
                  >
                    {apiMessage}
                  </div>

                  {showresendotp && (
                    <div className="pb-1 ">
                      <p className="mb-0">
                        {strings.cmn_otp} {strings.resend} via{" "}
                        <a href="#" onClick={(e) => requestOTP(OTP_TYPES.SMS)}>
                          {" "}
                          SMS{" "}
                        </a>{" "}
                        or{" "}
                        <a
                          href="#"
                          onClick={() => requestOTP(OTP_TYPES.WHATSAPP)}
                        >
                          {strings.whatsapp}
                        </a>
                      </p>
                    </div>
                  )}

                  {visibleContinue && (
                    <Button
                      classes="inp "
                      label={strings.continue}
                      click={handleSubmit}
                      inputType="text"
                    />
                  )}

                  {visibleSubmit && (
                    <Button
                      classes="inp"
                      label={strings.verify}
                      click={handleOTPSubmit}
                      inputType="text"
                    />
                  )}

                  <div className="or-devide">
                    <hr />
                    <span>or</span>
                  </div>
                  <div className="continue-with">
                    <p className="text-center mb-0">{strings.cmncontinue}</p>
                    <SignInButton
                      classes={"sign-b"}
                      label={strings.signin}
                      onclick={onSignInClick}
                    />
                    <p className="text-left mb-0 mt-4 ml-3">
                      {strings.rekyc_option}{" "}
                      <span className="normal-link" onClick={openReKYC}>
                        here.
                      </span>
                    </p>
                    <p className="text-left mb-0 ml-3">{strings.tariff_plan} <a href={process.env.PUBLIC_URL + "/doc/ACUMEN TARIFF PLAN.pdf"} target="_blank">Tariff Plan</a></p>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
};
export default Home;
